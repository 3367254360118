import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import TeamMembers from "@/presentation/components/team-members/TeamMembers.vue";
import TransformUtil from '@/commons/TransformUtil';
import DIContainer from "@/core/DIContainer";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import RoutePath from '@/commons/RoutePath';
import Alert from "@/presentation/components/modals/alert/Alert.vue";
export default defineComponent({
    name: "Confirm",
    components: {
        TeamMembers: TeamMembers,
        ErrorMessage: ErrorMessage,
        Alert: Alert
    },
    props: ["listBreadcrumb"],
    setup: function (props) {
        var store = useStore();
        var router = useRouter();
        var route = useRoute();
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        var state = reactive({
            dataApply: {},
            errorMessage: {},
            message: "",
            loading: false,
            team: {},
            showAlertPopup: false,
            alertContent: {
                title: "",
                content: ""
            },
        });
        function onBack() {
            router.back();
        }
        function onSubmit() {
            state.loading = true;
            var projectId = route.params.projectId;
            var data = JSON.parse(JSON.stringify(state.dataApply));
            delete data.nick_name;
            if (data.team_id) {
                data.account_id = null;
            }
            else {
                delete data.team_id;
            }
            projectInteractor.applyProject(projectId, data).then(function (result) {
                state.showAlertPopup = true;
                state.alertContent = {
                    title: "プロジェクト応募完了",
                    content: "プロジェクト応募完了しました。"
                };
                state.loading = false;
                store.commit("setProjectApply", {});
                setTimeout(function () {
                    router.push(RoutePath.PROJECT_DETAIL + route.params.projectId);
                }, 3000);
            }).catch(function (error) {
                state.loading = false;
                if (error.validation_errors) {
                    state.errorMessage = error.validation_errors;
                }
                if (error.message) {
                    if (error.code == 400) {
                        state.alertContent = {
                            title: "",
                            content: error.message
                        };
                        state.showAlertPopup = true;
                    }
                }
            });
        }
        function closePopup() {
            state.showAlertPopup = false;
            router.push(RoutePath.PROJECT_DETAIL + route.params.projectId);
        }
        onMounted(function () {
            props.listBreadcrumb[2] = {
                name: "応募内容確認",
                route: ""
            };
            state.dataApply = store.state.projectApply;
            state.dataApply.estimate_price_convert = TransformUtil.formatNumber(state.dataApply.estimate_price);
            if (!state.dataApply.account_id) {
                router.back();
            }
            if (state.dataApply.team_id) {
                teamInteractor.getTeamById(state.dataApply.team_id).then(function (result) {
                    if (!result.data) {
                        return;
                    }
                    state.team = result.data;
                    if (state.team && state.team.members) {
                        state.team.members.map(function (member) {
                            member.user_rating_ave = TransformUtil.pointRound(member.user_rating_ave);
                            member.user_rating_count = TransformUtil.formatNumber(member.user_rating_count);
                        });
                        state.team.members = state.team.members.sort(function (a, b) { return b.is_leader - a.is_leader; });
                    }
                });
            }
        });
        return {
            state: state,
            onBack: onBack,
            onSubmit: onSubmit,
            closePopup: closePopup
        };
    }
});
