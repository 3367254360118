import { defineComponent, watchEffect } from "vue";
import Star from "@/presentation/components/star-profile/StarProfile.vue";
import { useRouter } from "vue-router";
import TransformUntil from "@/commons/TransformUtil";
export default defineComponent({
    name: "TeamMembers",
    components: {
        Star: Star
    },
    props: ["teamMembers"],
    setup: function (props) {
        var router = useRouter();
        function gotoUserAchievement(userId) {
            router.push("/user/profile/" + userId + "/achievement");
        }
        function gotoUserInfo(userId) {
            router.push("/user/profile/" + userId + "/info");
        }
        watchEffect(function () {
            if (props.teamMembers) {
                props.teamMembers.forEach(function (member) {
                    member.total_count = member.total_count ? new Intl.NumberFormat().format(member.total_count) : 0;
                    member.user_rating_ave = TransformUntil.pointRound(member.user_rating_ave);
                    member.user_rating_count = TransformUntil.formatNumber(member.user_rating_count);
                });
            }
        });
        return {
            props: props,
            gotoUserAchievement: gotoUserAchievement,
            gotoUserInfo: gotoUserInfo
        };
    }
});
