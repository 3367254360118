<template>
    <span class="star_component">
        <span v-for="star of [1,2,3,4,5]" :key="star">
            <span class="star full" v-if="star <= props.point"></span>
            <span class="star half" v-else-if="star > props.point && props.point > star - 1"></span>
            <span class="star empty" v-else></span>
        </span>
    </span>
</template>

<script lang="ts" src="@/presentation/components/star-profile/StarProfile.ts" scoped>
</script>

<style lang="scss" src="@/presentation/components/star-profile/StarProfile.scss" scoped>
</style>