<template>
    <span class="error_message">
        {{ props.message }}
    </span>
</template>

<script lang="ts" src="@/presentation/components/error-message/ErrorMessage.ts" scoped>
</script>
<style lang="scss" src="@/presentation/components/error-message/ErrorMessage.scss" scoped>
</style>
