import { defineComponent } from "vue";
export default defineComponent({
    name: "StarComponent",
    props: ["point"],
    setup: function (props) {
        return {
            props: props
        };
    }
});
