import { defineComponent } from "vue";
export default defineComponent({
    name: "ErrorMessageComponent",
    props: ["message"],
    setup: function (props) {
        return {
            props: props
        };
    }
});
