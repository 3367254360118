<template>
  <div class="confirm">
    <div class="confirm__content">
      <h3>応募内容確認</h3>
      <p>下記の内容で問題ないかご確認ください。</p>
      <div class="confirm__content__form">
        <form action="" class="horizontal">
          <div class="form-ctrl">
            <label  class="text">ニックネーム</label>
            <div class="form-group">
              <span>{{state.dataApply.nick_name}}</span>
            </div>
          </div>
          <div class="form-ctrl">
            <label class="text">概算見積</label>
            <div class="form-group">
              <span>¥ {{state.dataApply.estimate_price_convert}}</span>
              <template v-if="state.errorMessage && state.errorMessage.estimate_price">
                  <ErrorMessage v-for="message of state.errorMessage.estimate_price" :key="message" :message="message"/>
              </template>
            </div>
          </div>
          <div class="form-ctrl">
            <label  class="text">見積の内訳や根拠</label>
            <div class="form-group">
              <pre class="price_description">{{state.dataApply.estimate_price_description}}</pre
              >
              <template v-if="state.errorMessage && state.errorMessage.estimate_price_description">
                  <ErrorMessage v-for="message of state.errorMessage.estimate_price_description" :key="message" :message="message"/>
              </template>
              <p v-if="state.team && state.team.name">{{state.team.name}}</p>
              <TeamMembers v-if="state.team && state.team.members" :teamMembers="state.team.members" />
            </div>
          </div>
          <div class="form-ctrl">
            <label  class="text">自己PR / 意気込み</label>
            <div class="form-group">
              <pre
                >{{state.dataApply.user_relations_description}}</pre
              >
              <template v-if="state.errorMessage && state.errorMessage.user_relations_description">
                  <ErrorMessage v-for="message of state.errorMessage.user_relations_description" :key="message" :message="message"/>
              </template>
              <template v-if="state.errorMessage && state.errorMessage.team_id">
                  <ErrorMessage v-for="message of state.errorMessage.team_id" :key="message" :message="message"/>
              </template>
            </div>
          </div>
        </form>
      </div>
      <div class="btn_group">
          <button class="btn-md btn-light-gray shadow" type="button" @click="onBack">入力画面に戻る</button>
          <button class="btn-md btn-blue shadow" type="button" @click="onSubmit" :disabled="state.loading" :class="{ submitting: state.loading }"><i v-if="state.loading" class="fas fa-circle-notch fa-spin"></i
                >この内容で応募する</button>
      </div>
    </div>
    <Alert
      v-if="state.showAlertPopup == true"
      :title="state.alertContent.title"
      :content="state.alertContent.content"
      @close="closePopup"
    />
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/project-apply/confirm/Confirm.ts">
</script>

<style lang="scss" src="@/presentation/views/user/project-apply/confirm/Confirm.scss" scoped>
</style>
