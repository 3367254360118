var TransformUtil = /** @class */ (function () {
    function TransformUtil() {
    }
    TransformUtil.convertShortText = function (str, limit) {
        if (limit === void 0) { limit = 100; }
        if (!str) {
            return "";
        }
        if (str.length > limit) {
            str = str.slice(0, limit);
            return str + "...";
        }
        return str;
    };
    TransformUtil.convertDatetime = function (date, symbol) {
        if (symbol === void 0) { symbol = '/'; }
        if (!date) {
            return "";
        }
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join(symbol);
    };
    TransformUtil.convertDatetimeLong = function (date, symbol) {
        if (symbol === void 0) { symbol = '/'; }
        if (!date) {
            return "";
        }
        else {
            date = date.replace(/-/g, symbol);
        }
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear(), hours = '' + d.getHours(), minutes = '' + d.getMinutes();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hours.length < 2)
            hours = '0' + hours;
        if (minutes.length < 2)
            minutes = '0' + minutes;
        return [year, month, day].join(symbol) + " " + [hours, minutes].join(":");
    };
    TransformUtil.formatPhoneNumber = function (str) {
        if (!str) {
            return "";
        }
        //Filter only numbers from the input
        var cleaned = ("" + str).replace(/\D/g, "");
        //Check if the input is of correct length
        var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
        var match2 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
        if (!match && !match2) {
            return str;
        }
        if (match2) {
            return "" + match2[1] + "-" + match2[2] + "-" + match2[3];
        }
        else {
            return "" + match[1] + "-" + match[2] + "-" + match[3];
        }
    };
    TransformUtil.formatNumber = function (number) {
        if (!number || Number(number) == NaN) {
            return 0;
        }
        return new Intl.NumberFormat('ja-JP', {}).format(number);
    };
    TransformUtil.pointRound = function (number) {
        if (!number || Number(number) == NaN) {
            return 0;
        }
        return Math.round(number * 10) / 10;
    };
    return TransformUtil;
}());
export default TransformUtil;
