<template>
  <div class="team_members">
    <table v-if="props.teamMembers">
      <tr v-for="member of props.teamMembers" :key="member">
        <td class="user-name" @click="gotoUserInfo(member.user_account_id)">{{member.user_account_name || member.user_nickname}} さん <span class="black" v-if="member.is_leader == 1">/ Master</span></td>
        <td>
          <span class="round-point"><span>-</span></span>
          評価 <Star :point="member.user_rating_ave" /> {{ member.user_rating_ave }} /
          <span @click="gotoUserAchievement(member.user_account_id)" class="blue-text">{{member.user_rating_count}}件</span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script lang="ts" src="@/presentation/components/team-members/TeamMembers.ts" scoped>
</script>

<style lang="scss" src="@/presentation/components/team-members/TeamMembers.scss" scoped>
</style>
